<template>
  <div class="yimiao">
    <div>
      <div>
        <van-cell-group>
          <van-field
            v-model="datafrom.OrgName"
            required
            @click="idListOrg = true"
            readonly="readonly"
            label="社区"
            placeholder="请选择社区"
          />
          <van-popup v-model="idListOrg" position="bottom" get-container="body">
            <van-picker
              show-toolbar
              :columns="OrganList"
              value-key="organname"
              @cancel="idListOrg = false"
              @confirm="onidListOrg"
            >
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group v-if="ShopCate == 1">
          <van-field
            v-model="datafrom.Region"
            @click="idListRId = true"
            readonly="readonly"
            label="小区"
            placeholder="请选择您所住小区"
          />
          <van-popup v-model="idListRId" position="bottom" get-container="body">
            <van-picker
              show-toolbar
              :columns="ridlist"
              value-key="Title"
              @cancel="idListRId = false"
              @confirm="onidListRId"
            >
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.Title"
            required
            label="商城名称"
            placeholder="请输入商城名称"
          />
        </van-cell-group>
        <van-field
          v-model="datafrom.STypeDesc"
          label="商城类型"
          placeholder="请选择商城类型"
          @click="shopTypePicker = true"
          readonly="readonly"
          clickable
        />
        <van-popup v-model="shopTypePicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="shopType"
            value-key="Name"
            @confirm="shopTypeQr"
            @cancel="shopTypePicker = false"
          />
        </van-popup>
        <van-field
          v-model="datafrom.LevelDesc"
          label="等级"
          placeholder="请选择等级"
          @click="shopTypePicker1 = true"
          readonly="readonly"
          clickable
        />
        <van-popup v-model="shopTypePicker1" position="bottom">
          <van-picker
            show-toolbar
            :columns="this.shopLevel"
            value-key="name"
            @confirm="shopTypeQr1"
            @cancel="shopTypePicker1 = false"
          />
        </van-popup>
        <van-cell-group>
          <van-field
            v-model="datafrom.HouseNo"
            label="门牌号"
            placeholder="请输入门牌号"
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.Charger"
            label="负责人"
            required
            placeholder="请输入负责人"
          />
        </van-cell-group>

        <van-cell-group>
          <van-field
            v-model="datafrom.Mobile"
            label="联系电话"
            required
            placeholder="请输入联系电话"
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.Tel"
            label="固定电话"
            placeholder="请输入固定电话"
          />
        </van-cell-group>
        <van-cell-group v-if="shopAdd == 3">
          <van-field
            v-model="datafrom.OriMobile"
            label="原手机"
            required
            placeholder="请输入原手机号"
          />
        </van-cell-group>
        <van-cell-group v-if="ShopCate == 1">
          <van-field
            v-model="datafrom.ICode"
            label="行业"
            placeholder="请输入行业"
          />
        </van-cell-group>
        <van-cell-group v-if="ShopCate == 1">
          <van-cell required title="是否重点监控">
            <van-radio-group
              v-model="datafrom.IsMonitor"
              direction="horizontal"
            >
              <van-radio name="1">是</van-radio>
              <van-radio name="2">否</van-radio>
            </van-radio-group>
          </van-cell>
        </van-cell-group>
        <div class="submit" style="padding: 10px 7px">
          <span style="color: red; font-size: 14px; letter-spacing: 8px"></span>
          <span style="color: #646566; font-size: 14px">商铺图片</span>
        </div>
        <van-uploader
          v-model="ShopImgList"
          multiple
          capture="camera"
          class="imgs"
          :max-count="1"
          :after-read="ShopImgRead"
          :before-delete="xcDelete"
          :deletable="true"
        />
        <div class="submit" style="padding: 10px 7px">
          <span style="color: red; font-size: 14px; letter-spacing: 8px"></span>
          <span style="color: #646566; font-size: 14px">营业执照</span>
        </div>
        <van-uploader
          v-model="jkImageList"
          multiple
          capture="camera"
          class="imgs"
          :max-count="1"
          :after-read="afterRead"
          :before-delete="jkDelete"
          :deletable="true"
        />
        <van-cell-group v-if="ShopCate == 1">
          <van-field
            v-model="datafrom.FireType"
            required
            label="消防器材类型"
            placeholder="请输入消防器材类型"
          />
        </van-cell-group>
        <van-cell-group v-if="ShopCate == 1">
          <van-field
            v-model="datafrom.YearCheckTime"
            label="消防年检日期"
            @click="datafromOutTime = true"
            readonly="readonly"
            required
            placeholder="请选择消防器材年检日期"
          />
          <van-popup
            v-model="datafromOutTime"
            position="bottom"
            get-container="body"
          >
            <van-datetime-picker
              show-toolbar
              type="date"
              title="请选择消防器材年检日期"
              :min-date="minDate"
              :max-date="maxDate"
              @cancel="datafromOutTime = false"
              @confirm="ondatafromOutTime"
            >
            </van-datetime-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group v-if="ShopCate == 1">
          <van-field
            v-model="datafrom.FireCount"
            required
            label="消防器材数量"
            placeholder="请输入消防器材数量"
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.Address"
            label="详细地址"
            placeholder="请输入详细地址"
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.License"
            label="营业执照"
            placeholder="请输入营业执照编号"
          />
        </van-cell-group>
        <van-cell-group v-if="ShopCate == 1">
          <van-cell required title="状态">
            <van-radio-group v-model="datafrom.Status" direction="horizontal">
              <van-radio name="1">显示</van-radio>
              <van-radio name="2">隐藏</van-radio>
            </van-radio-group>
          </van-cell>
        </van-cell-group>
        <van-cell-group>
          <van-cell title="获取当前定位">
            <van-radio-group v-model="dwIndex" direction="horizontal">
              <van-radio name="1" @click="dwXuanze(1)">是</van-radio>
              <van-radio name="2" @click="dwXuanze(2)">否</van-radio>
            </van-radio-group>
          </van-cell>
        </van-cell-group>
        <div
          id="allmap"
          v-show="dWshow"
          style="height: 160px; width: 90%; margin: auto"
        ></div>
      </div>
      <!-- <div class="buttons">
        <van-button type="info" @click="postfrom()">提交</van-button>
      </div> -->
      <div class="buttons">
        <van-button
          type="info"
          style="margin: 10px 0"
          v-if="bangdingShow"
          @click="postfrom(1)"
          >提交</van-button
        >
        <van-button
          type="info"
          style="margin: 10px 0"
          v-if="!bangdingShow"
          @click="postfrom(2)"
          >微信绑定</van-button
        >
        <van-button
          style="border: 1px solid #e6e6e6; margin: 10px 0"
          type="
          default"
          @click="fanhui()"
          >返回</van-button
        >
      </div>
    </div>
  </div>
</template>
<script type="text/javascript" src="//api.map.baidu.com/api?v=3.0&ak=L2s5R0CE405alnjKlsp35mNTySNAvZtd"></script>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
import { setOpenId, getOpenId } from "@/utils/auth";
import config from "@/config";
import Compressor from "compressorjs";
import axios from "axios";
import { loadBMap } from "../fangyi/baiduLocation.js";
import { WxGetShopTypeList } from "@/api/shop";
import { WxGetBank } from "@/api/RealInfo";
Vue.use(Toast);
import {
  WeGetRegionList,
  WeGetBuildRoomList,
  WxSaveMyBank,
  WeGetAppletOrganList,
  GetOrgansNullToken,
  WxReBindShop,
} from "@/api/yeguang";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      ShopCate: "",
      // 营业执照
      jkImageList: [],
      //商铺图片
      ShopImgList: [],
      OrganList: [],
      ridlist: [],
      BIdlist: [],
      BUIdlist: [],
      BFIdlist: [],
      BRIdlist: [],
      diqu: [],
      guanxi: [],
      minzu: [],
      xveli: [],
      zhuangtai: [],
      hunyin: [],
      ocodelist: [],
      minDate: new Date(2021, 0),
      maxDate: new Date(2025, 0),
      NativeCantonCodedata: [],
      dwIndex: "2",
      sexlist: [
        { id: "1", Name: "男" },
        { id: "2", Name: "女" },
      ],
      yimiaolist: [
        { id: "0", Name: "未接种" },
        { id: "1", Name: "第一针" },
        { id: "2", Name: "第二针" },
      ],
      idList: {
        organCode: "",
        RId: undefined,
      },
      datafrom: {
        ShopId: 0, //id、
        OCode: "",
        Title: "", // 商铺名称、
        STypeId: "",
        Level: "",
        HouseNo: "",
        Charger: "", // 负责人、
        Mobile: "", // 手机、
        Tel: "", // 固话、
        Address: "", // 详细地址、
        Coordinate: "", // 地图坐标
        ShopImg: "", // 商铺图片
        License: "",
        Credentials: "", // 营业执照
        Status: "1",
        Remark: "", // 备注
      },
      shopType: [],
      shopLevel: [
        { name: "一星", val: 1 },
        { name: "二星", val: 2 },
        { name: "三星", val: 3 },
        { name: "四星", val: 4 },
        { name: "五星", val: 5 },
      ],
      shopTypePicker: false, //商铺类型
      shopTypePicker1: false, //商铺等级
      idListRId: false,
      idListOrg: false,
      addshow: false,
      verifyshow: true,
      OCodeshow: false,
      sexshow: false,
      Nationshow: false,
      yimiao: false,
      datafromBackTime: false,
      datafromOutTime: false,
      dWshow: false,
      bangdingShow: true,
      bjZuoBiao: "",
      shopAdd: this.$route.query.add,
    };
  },
  created() {
    this.GetOrgansNullToken();
    this.shopXq();
    this.zidianlist();
    this.ShopCate = this.$route.query.shopCate;
  },
  methods: {
    shopXq: function () {
      console.log(this.$route.query);
      if (this.$route.query.add == "1") {
        this.datafrom = {
          ShopId: 0, //id、
          OCode: "",
          Title: "", // 商铺名称、
          STypeId: "",
          Level: "",
          HouseNo: "",
          Charger: "", // 负责人、
          Mobile: "", // 手机、
          Tel: "", // 固话、
          Address: "", // 详细地址、
          Coordinate: "", // 地图坐标
          ShopImg: "", // 商铺图片
          License: "",
          Credentials: "", // 营业执照
          Status: "1",
          Remark: "", // 备注
        };
      } else if (this.$route.query.add == "2" || this.$route.query.add == "3") {
        console.log(333333);
        WxGetBank({
          shopId: this.$route.query.shopId,
        }).then((res) => {
          this.bangdingShow = true;
          if (res.data.code == 0) {
            this.datafrom = res.data.data;
            //重点监控
            this.bjZuoBiao = res.data.data.Coordinate;
            // this.datafrom.IsMonitor = res.data.data.IsMonitor + "";
            this.datafrom.Status = res.data.data.Status + "";
            this.datafrom.OCode = res.data.data.OCode;
            this.datafrom.OrgName = res.data.data.OName;
            // this.datafrom.Region = res.data.data.RName;
            this.datafrom.STypeId = res.data.data.STypeId;
            this.datafrom.STypeDesc = res.data.data.STypeDesc;
            this.RegionList(res.data.data.OCode);
            console.log(res.data.data);
            //商铺照片
            if (res.data.data.ShopImg != "") {
              this.ShopImgLists = [res.data.data.ShopImg];
              var arr = [];
              this.ShopImgLists.forEach(function (imgUrl) {
                arr.push({ url: imgUrl });
              });
              this.ShopImgList = arr;
              this.datafrom.ShopImg = res.data.data.ShopImg;
            }
            if (res.data.data.Credentials != "") {
              //营业执照
              this.jkImageLists = [res.data.data.Credentials];
              var arrYe = [];
              this.jkImageLists.forEach(function (imgUrl) {
                arrYe.push({ url: imgUrl });
              });
              this.jkImageList = arrYe;
              this.datafrom.Credentials = res.data.data.Credentials;
            }
            // this.datafrom.ShopTypeKind = res.data.data.ShopTypeKind;
            // this.datafrom.STypeDesc = res.data.data.ShopTypeDesc;
            // this.datafrom.Region = res.data.data.RName;
            // this.datafrom.IsMonitor = res.data.data.IsMonitor;
          }
          if (this.$route.query.add == "3") {
            this.bangdingShow = false;
          }
        });
      }
    },
    dwXuanze: function (row) {
      if (row == "1") {
        this.dingwei();
        this.dWshow = true;
      } else {
        this.dWshow = false;
        this.Coordinate = this.bjZuoBiao;
      }
    },
    dingwei: function () {
      var that = this;
      // 百度地图API功能
      window.initBaiduMapScript = () => {
        that.BMap = window.BMap;
        var map = new window.BMap.Map("allmap");
        var point = new that.BMap.Point(116.331398, 39.897445);
        map.centerAndZoom(point, 18);

        var geolocation = new that.BMap.Geolocation();
        var Coordinate = "";
        geolocation.getCurrentPosition(
          function (r) {
            if (this.getStatus() == BMAP_STATUS_SUCCESS) {
              var mk = new BMap.Marker(r.point);
              map.addOverlay(mk);
              map.panTo(r.point);
              this.Coordinate = [
                { lng: r.point.lng, lat: r.point.lat, of: "inner" },
              ];
              console.log(this.Coordinate);
              that.dingweiList(this.Coordinate);
              // alert('您的位置：' + r.point.lng + ',' + r.point.lat);
            } else {
              alert("failed" + this.getStatus());
            }
          },
          { enableHighAccuracy: true }
        );
        console.log(geolocation);
        this.dingweiList(geolocation);
      };
      loadBMap("initBaiduMapScript");
    },
    dingweiList: function (geolocation) {
      console.log(geolocation);
      this.Coordinate = geolocation;
    },
    // 字典查询
    zidianlist: function () {
      // 商铺类型
      WxGetShopTypeList({ shopCate: 2 }).then((res) => {
        if (res.data.code == "0") {
          this.shopType = res.data.data;
          console.log(this.shopType, " this.shopType ");
        }
      });
      // GetCodeEntrysList({ kind: "310" }).then((res) => {
      //   this.shopType = res.data.data;
      // });
    },
    // 确认商铺类型
    shopTypeQr(val) {
      this.datafrom.STypeId = val.STypeId;
      this.datafrom.STypeDesc = val.Name;
      this.shopTypePicker = false;
    },
    // 确认商铺等级
    shopTypeQr1(val) {
      console.log(val, "选中等级");
      this.datafrom.Level = val.val;
      this.datafrom.LevelDesc = val.name;
      this.shopTypePicker1 = false;
    },
    //上传营业执照
    afterRead(file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success(result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios.post(config.apiUploadUrl, formData, c).then((res) => {
            if (res.data.code === 0) {
              console.log(res.data.picurl);
              that.datafrom.Credentials = res.data.picurl[0];
              // that.datafrom.EvalImgs.push(res.data.data);
              file.status = "";
              file.message = "";
            } else {
              Toast.fail(res.data.msg);
            }
          });
        },
      });
    },
    //删除营业执照
    jkDelete(file) {
      this.jkImageList = [];
      this.datafrom.Credentials = "";
    },
    //上传商铺图片
    ShopImgRead(file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success(result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios.post(config.apiUploadUrl, formData, c).then((res) => {
            if (res.data.code === 0) {
              console.log(res.data.picurl);
              that.datafrom.ShopImg = res.data.picurl[0];
              // that.datafrom.EvalImgs.push(res.data.data);
              file.status = "";
              file.message = "";
            } else {
              Toast.fail(res.data.msg);
            }
          });
        },
      });
    },
    //删除商铺图片
    xcDelete(file) {
      this.ShopImgList = [];
      this.datafrom.ShopImg = "";
    },
    //上传核酸证明
    afterhsRead(file) {
      var that = this;
      file.status = "uploading";
      file.message = "上传中...";
      new Compressor(file.file, {
        quality: 0.5,
        success(result) {
          var formData = new FormData();
          let c = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          //构造一个 FormData，把后台需要发送的参数添加
          formData.append("file", result, result.name);
          axios.post(config.apiUploadUrl, formData, c).then((res) => {
            if (res.data.code === 0) {
              console.log(res.data.data);
              that.datafrom.Testify = res.data.data;
              // that.datafrom.EvalImgs.push(res.data.data);
              file.status = "";
              file.message = "";
            } else {
              Toast.fail(res.data.msg);
            }
          });
        },
      });
    },
    //删除核酸
    hsDelete(file) {
      this.hsImageList = [];
      this.datafrom.Testify = "";
    },
    // 提交
    postfrom: function (row) {
      console.log(this.Coordinate);
      console.log(this.datafrom);
      if (this.ShopCate == 1) {
        if (this.datafrom.OCode == "") {
          Toast.fail("请选择社区");
          return false;
        }
        // if (this.datafrom.Region == "") {
        //   Toast.fail("请选择小区");
        //   return false;
        // }
        if (this.datafrom.Title == "") {
          Toast.fail("请输入商铺名称");
          return false;
        }
        if (this.datafrom.License == "") {
          Toast.fail("请输入门牌号");
          return false;
        }
        if (this.datafrom.Charger == "") {
          Toast.fail("请输入负责人");
          return false;
        }
        if (this.datafrom.Mobile == "") {
          Toast.fail("请输入联系电话");
          return false;
        }
        if (this.datafrom.STypeDesc == "") {
          Toast.fail("请选择商品类型");
          return false;
        }

        if (this.datafrom.YearCheckTime == "") {
          Toast.fail("请选择消防器材年检日期");
          return false;
        }
        if (this.datafrom.FireCount == "") {
          Toast.fail("请输入消防器材数量");
          return false;
        }
        if (this.datafrom.FireType == "") {
          Toast.fail("请输入消防器材类型");
          return false;
        }
        if (this.datafrom.Address == "") {
          Toast.fail("请输入详细地址");
          return false;
        }
      } else {
        if (this.datafrom.OCode == "") {
          Toast.fail("请选择社区");
          return false;
        }
        if (this.datafrom.Title == "") {
          Toast.fail("请输入商铺名称");
          return false;
        }
        if (this.datafrom.Charger == "") {
          Toast.fail("请输入负责人");
          return false;
        }
        if (this.datafrom.Mobile == "") {
          Toast.fail("请输入联系电话");
          return false;
        }
        // if (this.datafrom.STypeDesc == "") {
        //   Toast.fail("请选择商品类型");
        //   return false;
        // }
        // if (this.datafrom.Address == "") {
        //   Toast.fail("请输入详细地址");
        //   return false;
        // }
      }
      console.log(getOpenId());
      this.datafrom.OpenID = getOpenId();
      // this.datafrom.OpenID = "ojciF5FL_BlLHTSPhVIfIQz_KR48";
      this.ShopCate = this.$route.query.shopCate;
      // this.datafrom.OpenID = "123321";
      if (this.Coordinate == "") {
        this.datafrom.Coordinate = "";
      } else {
        if (this.dWshow) {
          this.datafrom.Coordinate = JSON.stringify(this.Coordinate);
        } else {
          this.datafrom.Coordinate = this.bjZuoBiao;
        }
      }
      // this.datafrom.IsMonitor = Number(this.datafrom.IsMonitor);
      if (row == "1") {
        WxSaveMyBank(this.datafrom).then((res) => {
          if (res.data.code == 0) {
            Dialog.alert({
              title: "提示",
              message: "提交成功!",
            }).then(() => {
              this.$router.go(-1);
              // this.$router.push({
              //   name: "register",
              // });
            });
          } else {
            Dialog.alert({
              title: "提示",
              message: "提交失败!" + res.data.msg,
            }).then(() => {
              // this.datafrom.IsMonitor = this.datafrom.IsMonitor + "";
              // on close
            });
          }
        });
      } else if (row == "2") {
        WxReBindShop(this.datafrom).then((res) => {
          if (res.data.code == 0) {
            Dialog.alert({
              title: "提示",
              message: "绑定成功!",
            }).then(() => {
              this.$router.go(-1);
              // this.$router.push({
              //   name: "register",
              // });
            });
          } else {
            Dialog.alert({
              title: "提示",
              message: "提交失败!" + res.data.msg,
            }).then(() => {
              // this.datafrom.IsMonitor = this.datafrom.IsMonitor + "";
              // on close
            });
          }
        });
      }
    },
    gitocodelist() {
      GetOrgansNullToken().then((res) => {
        this.ocodelist = res.data.data;
      });
    },
    // 社区列表
    GetOrgansNullToken: function (row) {
      GetOrgansNullToken({ level: 4, kind: 1 }).then((res) => {
        this.OrganList = res.data.data;
      });
    },
    // 小区(组)列表
    RegionList: function (row) {
      WeGetRegionList({ organCode: row }).then((res) => {
        this.ridlist = res.data.data;
      });
    },
    // 房屋列表
    BuildRoomList: function (val) {
      WeGetBuildRoomList({
        bId: this.idList.BId,
        buId: this.idList.buId,
        bfId: val,
      }).then((res) => {
        console.log(res);
        this.BRIdlist = res.data.data;
      });
    },
    fanhui: function () {
      this.$router.go(-1);
    },
    // 社区选择器
    onidListOrg(val) {
      this.datafrom.OCode = val.organcode;
      this.datafrom.OrgName = val.organname;
      this.idListOrg = false;
      this.RegionList(val.organcode);
      // this.datafrom.Region = "";
      // this.BuildList(val.RId);
    },
    // 小区(组)选择器
    onidListRId(val) {
      this.datafrom.RId = val.RId;
      // this.datafrom.Region = val.Title;
      this.idListRId = false;
      this.BuildList(val.RId);
    },
    // 性别选择器
    onsexshow(val) {
      this.datafrom.sex = val.id;
      this.datafrom.sexName = val.Name;
      this.sexshow = false;
    },

    // 消防器材年检日期
    ondatafromOutTime(val) {
      this.datafrom.YearCheckTime = this.dateformat(val);
      this.datafromOutTime = false;
    },
    // 社区
    onocode(val) {
      console.log(val);
      this.datafrom.OrgName = val.selectedOptions[0].OrganName;
      this.RegionList(val.value);
      this.OCodeshow = false;
    },
    dateformat(val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let houser = val.getHours();
      let Minutes = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (houser >= 1 && houser <= 9) {
        houser = `0${houser}`;
      }
      if (Minutes >= 1 && Minutes <= 9) {
        Minutes = `0${Minutes}`;
      }
      // return `${year}-${month}-${day} ${houser}:${Minutes}`;
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style>
.imgs {
  margin: 0 15px;
}

.yimiao .inputFrom {
  margin: 15px 8%;
}

.yimiao .inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}

.yimiao .buttons {
  text-align: center;
}

.yimiao .buttons button {
  width: 90%;
  margin: 25px 5%;
  border-radius: 10px;
}

.buttons {
  text-align: center;
}

.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
</style>